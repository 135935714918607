.LiquidationThree .mainHeader {
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: 0 3px 6px rgb(0 0 0/12%);
}

.LiquidationThree .mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.LiquidationThree .mainHeader .headRt ul li {
    color: inherit;
}

.LiquidationThree.localDeals .mainHeader .headRt ul li.hiw {
    display: none;
}

.LiquidationThree .mainHeader .headRt ul a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.LiquidationThree .mainHeader .headRt ul a.active {
    color: black;
    mix-blend-mode: multiply;
}

.LiquidationThree .mainHeader .headRt #change-bg-font-color a.active,
.LiquidationThree .mainHeader .headRt #change-bg-font-color a:hover {
    color: var(--primColor);
    mix-blend-mode: normal;
}

.LiquidationThree.estate .mainHeader .headRt ul a.active {
    color: #fff;
    mix-blend-mode: initial;
}

.LiquidationThree .mainHeader .headRt ul li button {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
}

.LiquidationThree .mobHeader .headRt ul li button {
    min-width: initial !important;
    margin: 0;
    width: 40px;
}

.LiquidationThree .mainHeader .headRt ul li button .material-icons {
    padding-left: 10px;
}

.LiquidationThree .mainHeader .headRt ul a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: inherit;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.LiquidationThree .mainHeader .headRt ul a.active:after {
    width: 100%;
    left: 0;
}

.LiquidationThree .mainHeader .headRt ul a:hover:after {
    width: 100%;
    left: 0;
}

.LiquidationThree .mainHeader .headRt ul li:not(:last-child) {
    margin-right: 25px;
}

.LiquidationThree .nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.LiquidationThree .mainHeader .nav-standard-top-area-search-inner {
    margin-left: 25px;
}

.LiquidationThree .nav-standard-top-area-search-input {
    background: none;
    /* border: 1px solid #F5F5F5; */
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    background: #f2f2f2;
    border-radius: 40px;
}

.LiquidationThree .nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.LiquidationThree .nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.LiquidationThree .nav-standard-top-area-search-sumbit {
    width: 40px;
    border-radius: 50px;
    height: 40px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 3px;
    right: 5px;
    color: var(--tertColor);
    background: #e4e4f2;
    border: none;
    justify-content: center;
    align-items: center;
    color: #333;
}
.loginLiqThree {
    border-radius: 40px;
    padding: 8.5px 25px;
    border: none;
    white-space: nowrap;
    border: 2px solid var(--primColor);
    background-color: transparent;
    color: var(--primColor);
}
.loginLiqThree a {
    color: var(--primColor);
}
.LiquidationThree .subLotSearch {
    right: 20px;
}
.LiquidationThree .nav-standard-top-area-search-clear {
    width: 40px;
    height: 40px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: right;
    position: absolute;
    top: 0;
    right: 5px;
    color: var(--tertColor);
    background: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    color: #333;
}

.LiquidationThree .mainHeader .headLt {
    width: 100%;
    max-width: 250px;
    margin-right: 50px;
}

.LiquidationThree .mainHeader .nav-standard-logo {
    width: 100%;
    max-width: 250px;
    height: 80px;
}

.LiquidationThree .mainHeader .headLt .headerLogo {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    object-position: left;
    height: 100%;
    margin-right: 0px;
}

.LiquidationThree .headerDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 300px;
    background: #fff;
}

.LiquidationThree .headerDrawer .mobShopLocation.MuiListItem-root {
    flex-wrap: wrap;
}

.LiquidationThree .headerDrawer .mobShopLocation.MuiListItem-root p {
    font-size: 14px;
    padding-inline-start: 8px;
    margin: 0;
}

.LiquidationThree .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
    width: 100%;
    justify-content: space-between;
}

.LiquidationThree .headerDrawer .headerDrawerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    height: auto;
}

.LiquidationThree .headerDrawer .headerDrawerNavLinks hr {
    width: 100%;
}

.LiquidationThree .respNav,
.LiquidationThree .respNavBtn {
    display: none !important;
}

.LiquidationThree .respHeaderSearch .MuiPaper-root {
    padding: 15px;
}

.LiquidationThree .respHeaderSearch .nav-standard-top-area-search-input {
    height: 50px;
}

.LiquidationThree .respHeaderSearch button {
    height: 100%;
}

.auctionHasItems {
    padding: 15px 30px;
    background: #eaf4a8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}

.auctionHasItems h3 {
    font-size: 18px;
    font-weight: 500;
    color: #4f5815;
    margin-left: 30px;
}

.auctionHasItems img {
    width: 30px;
    height: auto;
    object-fit: contain;
}

.auctionHasItems h3:last-child {
    margin-bottom: 0;
}

.auctionHasItems h3 a {
    color: inherit;
    font-weight: 600;
}

.LiquidationThree .mainHeader .nav-standard-top-area-search {
    max-width: 370px;
    width: 100%;
    min-width: 300px;
    margin-right: 20px;
}
.LiquidationThree .mainHeader .become_button {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    transition: 200ms color ease-in-out;
    position: relative;
}

.LiquidationThree .mainHeader .nav-standard-top-area-search-inner {
    margin-left: 0px;
}

.LiquidationThree .textSliderWrapper {
    background: #000;
    color: #fff;
    text-align: center;
}

/* Responsive */

@media (max-width: 1440px) {
    .LiquidationThree.localDeals .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 15px;
    }
}

@media (max-width: 1275px) {
    .LiquidationThree .mainHeader .nav-standard-top-area-search {
        max-width: 300px;
        margin: 0px 20px;
        min-width: 160px;
    }
    .LiquidationThree .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 15px;
    }
    .LiquidationThree .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
@media (max-width: 1120px) {
    .LiquidationThree .mainHeader .headLt .headerLogo {
        min-width: 90px;
    }
    .LiquidationThree .mainHeader .become_button {
        font-size: 14px;
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
}
@media (max-width: 1024px) {
    .LiquidationThree .mainHeader {
        height: 80px;
    }

    .LiquidationThree .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 10px;
    }

    .LiquidationThree header .headLt img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .LiquidationThree .deskNav {
        display: none !important;
    }
    .LiquidationThree .respNav {
        display: block !important;
    }
    .LiquidationThree .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: var(--headerFontColor) !important;
    }

    .LiquidationThree .headRt ul {
        list-style: none;
        margin: 0;
    }

    .LiquidationThree .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .LiquidationThree .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);

        /* justify-content: space-between; */
    }

    .LiquidationThree .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .LiquidationThree .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    .LiquidationThree header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .LiquidationThree .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-family: var(--FuturaStdMedium);
        flex-wrap: wrap;
    }

    .LiquidationThree .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .LiquidationThree .headRt.respNav .navRespLinks a.active,
    .LiquidationThree .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .LiquidationThree .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .LiquidationThree .headRt.respNav .naLogoHead img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .LiquidationThree .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
    }

    .LiquidationThree .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .LiquidationThree .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .LiquidationThree .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .LiquidationThree .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .LiquidationThree .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    .LiquidationThree .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .LiquidationThree .navCategories .navBack {
        color: #8a8a8a;
    }

    .LiquidationThree .navCategories .MuiListItem-button {
        width: 100%;
    }
    .LiquidationThree .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }
    .LiquidationThree .headerSearchForm {
        width: 90%;
    }
    .LiquidationThree .mainHeader .headLt .headerLogo {
        width: 100px;
        height: 100%;
    }
}

@media (max-width: 600px) {
    .LiquidationThree .headRt.respNav .naLogoHead img {
        width: 150px;
        height: auto;
    }
    .LiquidationThree .textSliderWrapper {
        color: #fff;
    }
    .auctionHasItems {
        height: auto;
        padding: 5px 15px;
    }
    .auctionHasItems h3 {
        font-size: 15px;
        line-height: 24px;
        margin-left: 15px;
    }
}
