.donationModal .modal-body.cm-body {
    padding: 0;
    position: relative;
    max-height: 100%;
}

.donationModal .rtBack {
    background-size: cover;
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0;
    border-radius: 0 5px 5px 0;
    background-position: center;
}

.donationModal .rtBack .txtContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    backdrop-filter: blur(30px) brightness(1.3);
    width: 100%;
}

.donationModal .closeButton {
    position: absolute;
    z-index: 999;
    right: 0;
    color: white;
}

.donationModal .modal-header {
    display: none;
}

.donationModal .sbtnWrpr .primButton {
    margin-left: auto;
}

.donationModal .sbtnWrpr .primButton button,
.donationModal .sbtnWrpr .primButton {
    width: max-content;
}

.donationModal .radContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
}

.donationModal .regType label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    border: 1px solid #b7b7b7;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    padding-left: 36px;
}

.donationModal .regType label img {
    margin-right: 20px;
}

.donationModal .regType h5 {
    font-size: 14px;
    font-weight: 600;
}

.donationModal .regType label,
.donationModal .regType h5 {
    margin-bottom: 0;
}

.donationModal .regType .radioIcon {
    width: 20px;
    height: 20px;
    border: 2px solid var(--primColor);
    border-radius: 30px;
    position: absolute;
    left: 10px;
}

.donationModal .regType .radioIcon::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background: var(--primColor);
    position: absolute;
    top: 3px;
    right: 3px;
    transform: scale(0);
    transition: ease-in-out 0.3s;
}
.donationModal .regType input:checked + label .radioIcon::after {
    transform: scale(1);
}

.donationModal .regType input:checked + label {
    color: var(--primColor);
}

.donationModal .custom-modal .modal-dialog.modal-lg {
    max-width: unset;
    margin: 0 auto;
    width: 90vw;
    height: 90vh;
}

.donationModal .custom-modal .modal-dialog.modal-lg .lftDnrCntnr {
    /* height: 90vh; */
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.donationModal .custom-modal .modal-content {
    background: transparent;
}

/*NEW DONATION MODAL POPUP*/
.donationModalUpdate .custom-modal .modal-body {
    max-height: unset;
}

.donationModalUpdate .custom-modal .modal-header {
    display: none;
}

.donationModalUpdate .custom-modal .modal-content {
    background: transparent;
    position: relative;
}

.donationModalUpdate .updateDonWrapper {
    display: flex;
    align-items: stretch;
}

.donationModalUpdate .updateDonWrapper .lftDnrCntnr {
    background: white;
    /* padding: 15px; */
    border-radius: 10px;
    width: 100%;
    height: 540px;
    max-width: 480px;
    /* max-width: calc(100% - 420px); */
    overflow: hidden;
}

.donationModalUpdate .custom-modal .closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    padding: 7px;
    background: black;
}

.donationModalUpdate .custom-modal .rtBack {
    background-image: url('../../../assets/images/donation_back.png');
    background-size: contain;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 10px;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    background-position: center;
    max-width: 420px;
    background-repeat: no-repeat;
}

.donationModalUpdate .custom-modal .rtBack .txtContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    backdrop-filter: blur(30px) brightness(1.3);
    width: 100%;
    border-radius: 0 0 10px 10px;
    text-align: center;
}

.donationModalUpdate .dntnrSlideWrapper {
    height: 100%;
}

.donationModalUpdate .dntnrSlideWrapper .pyhdrTtle {
    padding: 20px 10px;
    text-align: center;
    box-shadow: 0 0 10px #00000026;
    position: relative;
}

.donationModalUpdate .dntnrSlideWrapper .pyhdrTtle .backButton {
    position: absolute;
    top: 13px;
    left: 10px;
    width: 40px;
    height: 40px;
    min-width: unset;
}

.donationModalUpdate .dntnrSlideWrapper .dnSldBtnCntnr {
    padding: 40px 30px;
    max-height: calc(100% - 260px);
    overflow: auto;
}

.donationModalUpdate .dntnrSlideWrapper .donAmountVle {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 28px;
}

.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiFormControlLabel-root {
    z-index: 0;
    width: 100%;
    margin: 0;
}

.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiCheckbox-root {
    position: absolute;
    z-index: 99;
    left: 70px;
}

.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiCheckbox-root.MuiButtonBase-root {
    padding: 0;
    width: 30px;
    height: 30px;
    box-shadow: none;
}

.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiFormControlLabel-label .MuiButtonBase-root,
.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiTypography-root {
    width: 100%;
}

.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiButtonBase-root {
    padding: 14px 40px;
    border-radius: 8px;
    padding-left: 54px;
    background: var(--primColor);
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-shadow: 0 2px 3px rgb(12 33 65 / 30%), 0 -1px 0 rgb(0 0 0 / 20%) inset,
        0 0 0 1px rgb(0 0 0 / 15%) inset;
}

.donationModalUpdate .dntnrSlideWrapper .trnsChkBox .MuiSvgIcon-root {
    color: white !important;
    fill: white !important;
}

.donationModalUpdate .dntnrSlideWrapper .primButton button {
    box-shadow: 0 2px 3px rgb(12 33 65 / 30%), 0 -1px 0 rgb(0 0 0 / 20%) inset,
        0 0 0 1px rgb(0 0 0 / 15%) inset;
}

.donationModalUpdate .dntnrSlideWrapper .trnsctnFeeInfo {
    height: 130px;
}

.donationModalUpdate .dntnrSlideWrapper .trnsctnFeeInfo .material-icons {
    font-size: 18px;
}

.donationModalUpdate .customInput .material-icons {
    color: gray;
}

.donationModalUpdate .slctdCardWrpr_top {
    height: 126px;
}

.donationModalUpdate .slctdCardWrpr {
    height: 258px;
}

@media (max-height: 650px) {
    .donationModalUpdate .modal-content {
        max-height: unset;
    }
}
